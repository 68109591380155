
import { ref, defineComponent } from 'vue'
import * as api from '../../../utils/api'
import Menu from '../../../components/menu/Hospital.vue'
import { useDialog } from 'naive-ui'

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			unsubscribe: [] as string[],
			list: [] as any,
			inited: false,
			isAllSeled: false,
			allCancelable: false,
			archiveOnly: false,
			loading: false,
			allTicket: 0,
			initialUsedTicket: 0,
			usedTicket: 0,
			max: 0,
			min: 0,
			value: 0,
			cancelableCt: 0,
			errorDialog,
		}
	},
	mounted: async function () {
		const isArchiveOnly = location.pathname !== '/hospital/subscribe/remove'
		const title = isArchiveOnly ? 'やり取り停止' : 'サブスクリプションの解約'
		document.title = title + ' - Vets Index'
		this.archiveOnly = isArchiveOnly
		try {
			const data = await api.get('/v1/hospital/subscribe/list')
			this.list = data.list
			const allTicket = data.myData.allTicket
			this.allTicket = allTicket
			this.initialUsedTicket = allTicket - data.myData.remainTicket
			this.usedTicket = this.initialUsedTicket
			const max = allTicket - data.cancelableCt
			this.max = allTicket - max < 1 ? max - 1 : max
			this.inited = true
			this.allCancelable = data.allCancelable
			this.cancelableCt = data.cancelableCt
		} catch (e: any) {
			this.inited = true
			this.errorDialog(e, () => true)
		}
	},
	methods: {
		calc: function (amount: number) {
			const yen = amount * 9680
			if (yen < 0) return '-'
			return yen.toLocaleString()
		},
		selectAll: function () {
			if (this.isAllSeled) {
				this.unsubscribe = []
				this.isAllSeled = false
			} else {
				for (const s of this.list) {
					if (!s.unremoveable) this.unsubscribe.push(s.id)
				}
				this.isAllSeled = true
			}
		},
		getNewTicket: function () {
			const remain = this.allTicket - this.value
			return remain
		},
		getNewPrice: function () {
			const remain = this.allTicket - this.value
			return this.calc(remain)
		},
		refreshUsedTicket: function () {
			this.usedTicket = this.initialUsedTicket - this.unsubscribe.length
		},
		handleUpdateValue: function (value: string[]) {
			console.log('change fired')
			this.refreshUsedTicket()
			const max = this.allTicket - this.cancelableCt + value.length
			this.max = this.allTicket - max < 1 ? max - 1 : max
		},
		cancel: async function () {
			const amount = this.allTicket - this.value
			//this.value = 1
			const unsubscribe = this.unsubscribe
			try {
				this.loading = true
				if(unsubscribe.length) await api.post(`/v1/hospital/apply/archive`, unsubscribe)
				if(this.value) await api.post('/v1/hospital/subscribe/cancel', { amount })
				this.loading = false
				alert('完了しました。月額料金に変更がある場合は、決済業者の確認が取れるまで購入状況の確認はできません。')
				this.$router.push('/hospital/subscribe')
			} catch (e: any) {
				this.loading = false
				alert(e.message || 'エラー')
			}
		},
		allCancel: async function() {
			alert('現在この機能は準備中です。')
		}
	},
})
